import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"

const notFound = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="author" content="Alp Kahvecioglu"></meta>
        <title>alp kahvecioglu -- 404</title>
        <html lang="en" />
      </Helmet>
      <Header />
      <h1>what were you looking for?</h1>
      <Footer />
    </div>
  )
}

export default notFound
